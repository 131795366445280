import React from "react";
import { Element } from "react-scroll";
import { AccordionData, CoreValues, ServiceLists } from "../../Data";
import Accordion from "../Accordion/Accordion";

const Services = () => {
  return (
    <>
      <Element name="services">
        <div className="h-full bg-lightblue bg-opacity-[25%] p-10 max-md:p-5">
          <div className="flex flex-col  items-center text-center gap-4">
            <p className="text-main font-bold text-lg lg:text-xl leading-none uppercase">
              Our Services
            </p>
            <h1 className="font-bold text-[64px] max-md:text-[40px]  capitalize text-black leading-none">
              Our Services will be extended to
            </h1>
            <p className="w-[600px] max-md:w-[300px] text-[22px] lg:text-[26px] text-slate-800 capitalize">
              entrepreneurs, small, medium and large-scale business
              organizations which require Human Resource Management, Legal and
              Compliance, IT, Finance and administrative services
            </p>
          </div>
          <div className="flex max-md:flex-col justify-center gap-24 max-md:gap-4 mt-20 max-md:my-10">
            <div className="border-t-2 border-black w-[450px] max-md:w-full">
              <p className="text-[18px] lg:text-[20px] p-4  uppercase max-md:text-justify">
                Agrah is committed to do right thing in the right way with right
                people and right processes
              </p>
            </div>
            <div>
              <div className="w-[450px] max-md:w-full z-[1]">
                <ul>
                  {ServiceLists.map((servicelist) => (
                    <li
                      key={servicelist.id}
                      className="border-t-2 text-black text-[22px] lg:text-[26px] py-4 border-black max-md:pl-4 flex justify-between"
                    >
                      {servicelist.listItem}
                      <p
                        key={servicelist.unit}
                        className="text-main max-md:hidden "
                      >
                        {servicelist.unit}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full p-10">
          <div className="flex flex-col gap-8 items-center text-center">
            <p className="text-main font-bold text-lg lg:text-xl leading-none uppercase">
              core values
            </p>
            <h1 className="font-bold text-[64px] max-md:text-[40px] capitalize text-black">
              Our core values are as follows
            </h1>
            <div className="flex max-md:flex-col z-[-10] items-center justify-center gap-8">
              {CoreValues.map((corevalue, index) => (
                <div key={corevalue.id} className="relative h-[490px]">
                  <div
                    className={`shadow-2xl rounded-[15px] w-[280px] h-[390px] p-6 ${
                      index === 2 ? "bg-main" : "bg-gray"
                    }`}
                  >
                    <p className="text-[22px] font-medium">
                      {corevalue.details}
                    </p>
                  </div>
                  <div className="bg-white text-black font-medium text-[22px] shadow-2xl rounded-[15px] w-[220px] h-[100px] p-6 absolute bottom-10 left-[10%]">
                    {corevalue.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-slate-100/10">
            <h1 className="py-8 text-[48px] font-bold text-left max-md:text-center">
              Services From Agrah Consultants
            </h1>
            <Accordion items={AccordionData} />
          </div>
        </div>
      </Element>
    </>
  );
};

export default Services;
