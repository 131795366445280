import React, { useState } from "react";
import { Link } from "react-scroll";
import { Element } from "react-scroll";
import bg1 from "../../assets/bg-1.png";
import Button from "../Button/Button";
const Hero = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <>
      <Element name="home">
        <div className="flex max-md:flex-col-reverse items-center mx-[5rem] lg:mx-[10rem] py-20 lg:py-[10rem] max-md:gap-6 justify-between lg:gap-16">
          <div className="flex flex-col gap-6">
            <h1 className="text-[60px] max-md:text-[46px] max-md:text-left w-[600px]  max-md:w-[300px] text-main font-bold capitalize">
              We give you focus by taking over all administrative burdens
            </h1>
            <p className="text-[18px] max-md:text-[22px] lg:text-[26px] text-black w-[600px]  max-md:w-[300px]">
              Agrah Consultants (Pvt) Ltd is a business process outsourcing
              company established in Sri Lanka with the objective of being a
              service and solution provider to both local and international
              businesses and investors in Sri Lanka.
            </p>
            <Link
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              onClick={() => {
                handleNav();
              }}
            >
              <Button value={"Learn More"} textColor="#FEC601" />
            </Link>
          </div>
          <div>
            <img
              src={bg1}
              alt="AGRAH holdings"
              className="w-[450px] rounded-full"
            />
          </div>
        </div>
      </Element>
    </>
  );
};

export default Hero;
