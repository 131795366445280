import React from "react";
import { Element } from "react-scroll";
import { TeamLists } from "../../Data";

const Team = () => {
  return (
    <>
      <Element name="team">
        <div className="flex flex-col gap-10 py-10 items-center text-center max-md:p-5">
          <p className="text-main font-bold text-lg lg:text-xl leading-none uppercase">
            Our Team
          </p>
          <h1 className="font-bold text-[64px] max-md:text-[40px] capitalize text-black">
            our team consists of the best
          </h1>
          <div className="flex max-md:flex-col gap-8 items-center justify-center">
            {TeamLists.map((team) => (
              <div
                key={team.id}
                className="w-[300px] h-[640px] lg:w-[370px] lg:h-[750px] max-md:h-full p-6 rounded-xl shadow-xl bg-gray "
              >
                <div className="flex items-center justify-center pb-6">
                  <img
                    src={team.image.src}
                    alt={team.image.alt}
                    className="w-[200px] "
                  />
                </div>
                <div className="">
                  <p className="font-bold text-center lg:text-[22px]">
                    {team.name}
                  </p>
                  <ul>
                    {team.qualifications.map(
                      (qualifications, qualificationsIndex) => (
                        <li
                          key={qualificationsIndex}
                          className="py-2 text-left lg:text-[24px]"
                        >
                          {qualifications}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Element>
    </>
  );
};

export default Team;
