import React, { useState } from "react";
import { Element } from "react-scroll";
import contact from "../../assets/contact.png";
import { useForm, ValidationError } from "@formspree/react";

function Contact() {
  const [state, handleSubmit] = useForm("mrgnzjoq");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    await handleSubmit(event);
    // Check if the submission was successful
    if (state.succeeded) {
      // Clear the form data
      setFormData({
        name: "",
        email: "",
        contact: "",
        message: "",
      });
    }
  };

  const handleInputChange = (e) => {
    // Update the form data on input change
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <Element name="contact">
      <div className="py-10 mx-10 lg:mx-[10rem]">
        <p className="text-main font-bold text-lg lg:text-xl leading-none text-center uppercase pb-14">
          Contact Us
        </p>
        <div className="flex justify-center gap-[10rem] max-md:gap-0 max-md:flex-col">
          <img src={contact} alt="agrah consulting" className="w-[500px]" />
          <form
            onSubmit={handleFormSubmit}
            action="https://formspree.io/f/mrgnzjoq"
            method="POST"
          >
            <div className="flex flex-col gap-4 max-md:my-6 w-[500px] max-md:w-[300px]">
              <label htmlFor="name" className="lg:text-[24px] form-label">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Name"
                required
                className="lg:text-[24px] form-control border-b-2 border-main outline-none w-full"
              />
              <ValidationError
                prefix="Name"
                field="name"
                errors={state.errors}
              />

              <label htmlFor="email" className="lg:text-[24px] form-label">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Email Address"
                required
                className="lg:text-[24px] form-control border-b-2 border-main outline-none w-full"
              />
              <ValidationError
                prefix="Email"
                field="email"
                errors={state.errors}
              />

              <label htmlFor="contact" className="lg:text-[24px] form-label">
                Contact Number
              </label>
              <input
                type="text"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                placeholder="Contact Number"
                required
                className="lg:text-[24px] form-control border-b-2 border-main outline-none w-full"
              />
              <ValidationError
                prefix="Contact"
                field="contact"
                errors={state.errors}
              />

              <label htmlFor="message" className="lg:text-[24px] form-label">
                Message
              </label>
              <input
                id="message"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                placeholder="Message"
                required
                className="lg:text-[24px] form-control border-b-2 border-main outline-none w-full"
              ></input>
              <ValidationError
                prefix="Message"
                field="message"
                errors={state.errors}
              />

              {state.succeeded && (
                <p className="text-green-500 text-xl lg:text-2xl">Thank You!</p>
              )}

              <button
                type="submit"
                disabled={state.submitting}
                className="bg-main rounded-full py-2 font-bold text-xl"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Element>
  );
}

export default Contact;
