import React from "react";

const Button = (props) => {
  return (
    <div className="hover:bg-main border-2 border-lg border-main py-2 pl-6 text-main font-bold rounded-full  hover:text-white delay-100 lg:text-2xl ease-out cursor-pointer">
      {props.value}
    </div>
  );
};

export default Button;
