import React from "react";
import { FaWhatsapp } from "react-icons/fa6";

const WhatsAppChatButton = () => {
  // Replace '1234567890' with the actual phone number you want to open the chat with.
  const phoneNumber = "+94 077 259 7678";

  // Construct the WhatsApp chat link.
  const whatsappChatLink = `https://api.whatsapp.com/send?phone=${phoneNumber}`;

  return (
    <a href={whatsappChatLink} target="_blank" rel="noopener noreferrer">
      <button className="bg-black w-[500px] max-md:w-full flex items-center gap-4 justify-center text-white p-3 lg:text-[26px] text-md  transition duration-300 hover:scale-110">
        <FaWhatsapp />
        Reach Us
      </button>
    </a>
  );
};

export default WhatsAppChatButton;
