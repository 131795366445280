import prasangaImage from "./assets/priyanga.png";
import SrimanthaImage from "./assets/srimantha.png";
import ChampikaImage from "./assets/champi.png";

export const ServiceLists = [
  {
    id: 1,
    listItem: "Human Resource Management",
    unit: "01",
  },
  {
    id: 2,
    listItem: "Legal and Compliance",
    unit: "02",
  },
  {
    id: 3,
    listItem: "IT and AI services",
    unit: "03",
  },
  {
    id: 4,
    listItem: "Administrative Services",
    unit: "04",
  },
  {
    id: 5,
    listItem: "Financial Services",
    unit: "05",
  },
];

export const CoreValues = [
  {
    id: 1,
    details:
      "Our quality of service will not be simply satisfying customer expectations it will be beyond levels of expectation.",
    title: "Excellence",
  },
  {
    id: 2,
    details:
      "Our team will be dedicated 365 days of the year to provide solutions and processes which will ensure our clients not only gain satisfaction but sustainable growth and profits.",
    title: "Commitment",
  },
  {
    id: 3,
    details:
      "Our team will ensure to provide our services within the legal framework and respective Industry standards in Sri Lanka.",
    title: "Ethical",
  },
  {
    id: 4,
    details:
      "Our integrity is our key strength and thus Agrah is committed to doing the right thing in the right way with the right people and right processes.",
    title: "Integrity",
  },
];

export const AccordionData = [
  {
    title: "Human Resource Management Services",
    number: "01",
    content: [
      "Recruitment and selection",
      "Training and Development",
      "Performance Management",
      "Legal services related to HR (drafting & investigations)",
      "Coaching  and counselling",
    ],
  },
  {
    title: "Legal and Compliance",
    number: "02",
    content: [
      "Advice and Opinion on legal disputes",
      "Litigation (mediation Board, Arbitration, Labor Tribunals and, Magistrate - courts)",
      "Drafting Agreements and correspondence",
      "Compliance framework to comply local and international Industry standards",
      "Notary Certification and Company Secretarial functions",
      "Investigations (Internal and external)",
    ],
  },
  {
    title: "ICT services",
    number: "03",
    content: [
      "Computer Hardware related services",
      "Digital Marketing",
      "ATL & BTL Advertising",
      "CCTV and Networking",
      "Strategic IT planning",
      "Digital Transformation",
      "Data Analytics and Business Intelligence",
      "Cybersecurity Strategy and IT governance and compliance",
      "Cloud computing and Infrastructure",
      "Project and program management",
      "Technology Leadership and Talent Development",
    ],
  },
  {
    title: "Administrative Services",
    number: "04",
    content: [
      "Guidance and conduct matters related to Banks and financial Institutes",
      "State Departments and Authorities",
      "Land Registry,Registrar of Companies,Inland Revenue",
      "Event Management",
      "Organising local and overseas tours",
    ],
  },
  {
    title: "Financial Services",
    number: "05",
    content: [
      "Book keeping and Accounting",
      "Tax consultancy, tax planning, and computation",
      "Financial and Internal Audits",
      "Payroll management",
    ],
  },
];

export const TeamLists = [
  {
    id: 1,
    image: {
      src: prasangaImage,
      alt: "Prasanga Ratnayake Image",
    },
    name: "Prasanga Ratnayake",
    qualifications: [
      "LLB MHRM (Colombo)",
      "Attorney at Law, Commissioner for Oaths",
      "Managing Director Agrah Holdings",
      "Director Agrah Gems",
      "Director Agrah Consultants (Pvt) Ltd",
    ],
  },
  {
    id: 2,
    image: {
      src: SrimanthaImage,
      alt: "Srimantha Image",
    },
    name: "Srimantha Atulugama",
    qualifications: [
      "BIT (Colombo)",
      "MBA (Moratuwa)",
      "Head of IT at Agrah Consultants (Pvt) Ltd",
    ],
  },
  {
    id: 3,
    image: {
      src: ChampikaImage,
      alt: "ChampikaImage",
    },
    name: "Champika Udawatta",
    qualifications: [
      "MSC in Business and Occupation Psychology ",
      "MHRM (Colombo),ACPM",
      "Certified Transformative Coach,NDHRM",
      "Advance Certificate in Counselling",
      "Head of HR at Agrah Consultants (Pvt) Ltd",
    ],
  },
];
