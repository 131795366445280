import React, { useState } from "react";
import { Link } from "react-scroll";
import logo from "../../assets/logo.png";
const Footer = () => {
  const [nav, setNav] = useState(true);

  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <>
      <div className="bg-main text-black p-8  flex justify-between max-md:flex-col lg:px-[10rem]">
        <div className="">
          <img src={logo} alt="agrahholdings" />
          <p className="w-[420px] max-md:w-[300px] my-4 max-md:text-[20px]  lg:text-xl">
            Agrah Consultants (Pvt) Ltd is a business process outsourcing
            company established in Sri Lanka with the objective of being a
            service and solution provider to both local and international
            businesses and investors in Sri Lanka.
          </p>
        </div>
        <div className="flex gap-2 flex-col pb-4">
          <h1 className="py-2 max-md:text-[20px] lg:text-xl">
            Some Useful Links
          </h1>
          <Link
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => {
              handleNav();
            }}
          >
            Home
          </Link>
          <Link
            to="about"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => {
              handleNav();
            }}
          >
            About Us
          </Link>
          <Link
            to="services"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => {
              handleNav();
            }}
          >
            Services
          </Link>
          <Link
            to="team"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => {
              handleNav();
            }}
          >
            Team
          </Link>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={() => {
              handleNav();
            }}
          >
            Contact
          </Link>
        </div>
        <div>
          <p className="py-2 max-md:text-[20px]  lg:text-xl">
            Address : Mangala Road, Manning Town, Colombo 8
          </p>
          <p className="py-2 max-md:text-[20px]  lg:text-xl">
            Contact numbers: <br />
            <a href="tel:+0112697016">011-269-7016</a>
            <br />
            <a href="tel:+0772597678">077-259-7678</a>
            <br />
            <a href="tel:+0763505657">076-350-5657</a>
          </p>
          <p className="py-2 max-md:text-[20px]  lg:text-xl">
            Email :{" "}
            <a href="mailto:agrahholdings@gmail.com">agrahholdings@gmail.com</a>
          </p>
        </div>
      </div>
      <div className="bg-main text-center font-bold lg:text-xl">
        <p className="py-2 border-t">Designed & Developed by Webizera</p>
      </div>
    </>
  );
};

export default Footer;
