import React from "react";
import { Element } from "react-scroll";
import bg2 from "../../assets/bg-2.png";
import WhatsAppChatButton from "../WhatsappChat/WhatsappChat";
const About = () => {
  return (
    <>
      <Element name="about">
        <div className="h-full flex flex-col gap-16 py-10 mx-[5rem] lg:mx-[10rem]">
          <div className="flex max-md:flex-col items-center justify-between">
            <div>
              <img
                src={bg2}
                alt="agrah consultants"
                className="w-[450px] h-full rounded-full"
              />
            </div>
            <div className="flex flex-col max-md:w-[300px] gap-6 max-md:py-6">
              <div className="flex w-[520px] max-md:w-[300px] items-center justify-between">
                <p className="text-main font-bold text-lg lg:text-xl uppercase">
                  About Us
                </p>
                <p className="bg-main p-2 rounded-full text-white font-bold">
                  REG NO: 00289171
                </p>
              </div>
              <h1 className="pl-8 lg:pl-0 max-md:pl-0 text-[60px] max-md:text-[46px] max-md:text-left w-[600px]  max-md:w-[300px] text-black font-bold capitalize">
                Why should you select Agrah Consultants?
              </h1>
              <li className="pl-8 lg:pl-0 max-md:pl-0 text-[22px] lg:text-[26px] text-slate-800 w-[500px]  max-md:w-[300px]">
                We are just a call away for all your business requirements, 365
                days of the year.
              </li>
              <li className="pl-8 lg:pl-0 max-md:pl-0 text-[22px] lg:text-[26px] text-slate-800 w-[500px]  max-md:w-[300px]">
                We give you focus by undertaking all support services you need
                to run your business.
              </li>
              <li className="pl-8 lg:pl-0 max-md:pl-0 text-[22px] lg:text-[26px] text-slate-800 w-[500px]  max-md:w-[300px]">
                Customized and innovative solutions by our experienced panel of
                experts.
              </li>
              <div className="w-[500px] max-md:w-[300px] ml-8 max-md:ml-0 lg:ml-0">
                <WhatsAppChatButton />
              </div>
            </div>
          </div>
          <div className="flex max-md:flex-col items-center justify-center gap-8 text-white">
            <div className="p-8 w-[500px] max-md:w-[300px] h-[350px] max-md:h-full text-justify bg-slate-800">
              <h1 className="font-bold text-4xl max-md:text-[32px] text-center">
                Vision
              </h1>
              <p className="py-8 text-2xl max-md:text-[20px]">
                To be the most sought after business process outsourcing company
                and solution provider to local and international businesses in
                Sri Lanka.
              </p>
            </div>
            <div className="p-8 w-[500px] max-md:w-[300px] h-[350px] max-md:h-full text-justify bg-slate-800">
              <h1
                className="font-bold text-4xl
            max-md:text-[32px] text-center"
              >
                Mission
              </h1>
              <p className="py-8 text-2xl max-md:text-[20px]">
                Extend most cost effective, efficient and high in quality
                business processes and solutions that will ensure sustainable
                business growth and profits to our clients.
              </p>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
};

export default About;
