import React, { useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";

const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className="flex gap-6 flex-col ">
          <div
            className={`group shadow-lg  cursor-pointer ${
              index % 2 === 0 ? " bg-slate-800 bg-opacity-25" : "bg-white"
            }  rounded-xl  flex justify-between items-center p-10 max-md:p-3 ${
              activeIndex === index ? "active" : ""
            } transition-all duration-500`}
            onClick={() => handleItemClick(index)}
          >
            <p className="text-black text-[38px] max-md:hidden font-bold">
              {item.number}
            </p>
            <p className="text-black text-[38px] max-md:text-[20px] font-bold max-md:w-[190px]">
              {item.title}
            </p>
            {activeIndex === index ? (
              <FaTimes
                size={20}
                className="group-hover:text-main  group-hover:bg-slate-800 group-hover:bg-opacity-25 cursor-pointer transition-all duration-200 border w-[40px] max-md:w-[25px] max-md:h-[25px] h-[40px] rounded-full"
              />
            ) : (
              <FaPlus
                size={20}
                className="group-hover:text-main group-hover:bg-slate-800 group-hover:bg-opacity-25 cursor-pointer transition-all duration-200 border w-[40px] h-[40px] max-md:w-[25px] max-md:h-[25px] rounded-full"
              />
            )}
          </div>
          <div
            className={`shadow-lg px-4 ${
              index % 2 === 0 ? "bg-slate-800 bg-opacity-25" : "bg-white"
            } overflow-hidden transition-all duration-200 rounded-xl mb-4`}
            style={{ height: activeIndex === index ? "auto" : 0 }}
          >
            <ul>
              {item.content.map((content, contentIndex) => (
                <li
                  key={contentIndex}
                  className={`${
                    contentIndex === 0 ? "border-0" : "border-t "
                  }border-slate-800  lg:text-xl text-slate-800 py-4 `}
                >
                  {content}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
