import React, { useState } from "react";
import { Link } from "react-scroll";
import { animateScroll as scroll } from "react-scroll";
import logo from "../../assets/logo.png";
import { HiBars3 } from "react-icons/hi2";
import { TiTimes } from "react-icons/ti";

const Header = () => {
  const [nav, setNav] = useState(true);

  const scrollTo = (element) => {
    scroll.scrollTo(element, {
      smooth: "easeInOutQuart",
      offset: -70,
    });
  };

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <>
      <nav className="bg-main flex fixed w-full items-center justify-between py-2 px-7 max-md:px-4 lg:px-[10rem]">
        <div className="flex items-center gap-8 max-md:gap-2">
          <img
            src={logo}
            alt="agrah logo"
            className="w-[150px] lg:w-[250px] cursor-pointer"
            onClick={() => scrollTo("home")}
          />
        </div>
        <div className="flex items-center gap-6 max-md:hidden">
          <Link
            to="home"
            className="cursor-pointer hover:scale-105 hover:font-bold lg:text-[26px]"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Home
          </Link>
          <Link
            to="about"
            className="cursor-pointer hover:scale-105 hover:font-bold lg:text-[26px]"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            About Us
          </Link>
          <Link
            to="services"
            className="cursor-pointer hover:scale-105 hover:font-bold lg:text-[26px]"
            spy={true}
            smooth={true}
            offset={-65}
            duration={500}
          >
            Services
          </Link>
          <Link
            to="team"
            className="cursor-pointer hover:scale-105 hover:font-bold lg:text-[26px]"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Team
          </Link>
          <Link
            to="contact"
            className="cursor-pointer hover:scale-105 hover:font-bold lg:text-[26px]"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            Contact
          </Link>
        </div>

        <div onClick={handleNav} className="cursor-pointer md:hidden">
          {!nav ? (
            <TiTimes size={45} className="text-white z-[100]" />
          ) : (
            <HiBars3 size={45} className="text-white" />
          )}
          <div
            className={
              !nav
                ? "bg-black fixed z-[100] h-full left-0 top-0 w-[67%] ease-in duration-500"
                : "fixed left-[-100%]"
            }
          >
            <div className="text-white flex gap-8 flex-col w-[100%] text-xl py-8 items-center h-auto justify-center md:hidden ">
              <Link
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="border-b border-white pb-4 w-full flex items-center justify-center"
                onClick={() => {
                  handleNav();
                }}
              >
                Home
              </Link>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="border-b border-white pb-4 w-full flex items-center justify-center"
                onClick={() => {
                  handleNav();
                }}
              >
                About Us
              </Link>
              <Link
                to="services"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="border-b border-white pb-4 w-full flex items-center justify-center"
                onClick={() => {
                  handleNav();
                }}
              >
                Services
              </Link>
              <Link
                to="team"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="border-b border-white pb-4 w-full flex items-center justify-center"
                onClick={() => {
                  handleNav();
                }}
              >
                Team
              </Link>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                className="border-b border-white pb-4 w-full flex items-center justify-center"
                onClick={() => {
                  handleNav();
                }}
              >
                Contact
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
